<template>
  <div class="company-page">
    <h1>会社概要</h1>
    
    <dl>
      <dt>会社名</dt>
      <dd>メノア株式会社</dd>
      
      <dt>代表取締役</dt>
      <dd>
        <ul>
          <li>吉川雄司</li>
          <li>柿本竜也</li>
        </ul>
      </dd>
      
      <dt>設立日</dt>
      <dd>2024年6月3日</dd>
      
      <dt>所在地</dt>
      <dd>〒150-0002 東京都渋谷区 渋谷1-1-3 アミーホール</dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'CompanyView',
  mounted() {
    document.title = '会社概要 | メノア株式会社';
  }
};
</script>

<style scoped>
.company-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

dl {
  margin-left: 20px;
}

dt {
  font-weight: bold;
  margin-top: 10px;
}

dd {
  margin-left: 0;
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}
</style>