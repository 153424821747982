<template>
  <div class="privacy-policy">
    <h1>プライバシーポリシー</h1>
    <p>メノア株式会社（以下「当運営者」といいます。）は、当運営者が管理するサービス「メノア」及び同サービスに関連するすべてのサービス（以下「本サービス」と総称します。）におけるユーザーについての個人情報を含むユーザーの情報（以下「ユーザー情報」といいます。）の取り扱いについて、以下の通りプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。</p>

    <h2>1. 当運営者が取得するユーザー情報</h2>
    <p>当運営者が、本サービスにおいて取得するユーザー情報は、以下の通りです。</p>
    <ul>
      <li>メールアドレスの情報</li>
      <li>アクション情報</li>
      <li>本サービスの利用情報
        <ul>
          <li>端末情報</li>
          <li>ログ情報</li>
        </ul>
      </li>
    </ul>
    <p>※ 本サービスでは、ユーザーがより便利に本サービスを利用して頂けるよう「Cookie」という技術を使用することがあります。これは、ユーザーのコンピュータが本サービス上のどのページにアクセスしたかを記録しますが、ユーザーが本サービスにおいてご自身の個人情報を入力されない限りユーザーを特定したり、識別することはできません。Cookie の使用を希望されない場合は、ご本人のブラウザの設定を変更することにより、Cookie の使用を拒否することができますが、その場合には、本サービスの一部又は全部をご利用できなくなる場合がありますので、あらかじめご了承ください。</p>

    <h2>2. ユーザー情報の利用目的</h2>
    <p>当運営者は取得したユーザー情報を以下の目的で利用いたします。</p>
    <ul>
      <li>ユーザーごとにカスタマイズされたコンテンツの提供、広告の配信をするため</li>
      <li>利用規約違反その他本サービスの不正・不当利用に対する対応のため</li>
      <li>データ入力補助その他ユーザーが本サービスを円滑に利用できるようにするため</li>
      <li>本サービスの利用に関する統計データを作成するため</li>
      <li>ユーザーからのお問い合わせ等に対する対応のため</li>
      <li>本サービスに関する情報等を告知するため</li>
      <li>今後の本サービスに関する新企画立案を行い提供するため</li>
      <li>有料サービス利用時等における請求処理のため</li>
      <li>マーケティング調査及び分析のため</li>
      <li>その他本サービスに関するお知らせをしたり、必要に応じた連絡を行ったりするため</li>
      <li>その他、上記利用目的に付随する目的のため</li>
    </ul>

    <h2>3. ユーザー情報の第三者への開示・提供</h2>
    <p>当運営者は、取得したユーザー情報を、本条第２項および以下の場合を除き、第三者に提供することはございません。</p>
    <ul>
      <li>第三者に提供することについて事前の同意を頂いている場合</li>
      <li>個人情報保護法及びその他法令に基づく場合</li>
      <li>ユーザーが本サービスの利用規約に違反し、当運営者の権利、財産やサービス等を保護するために、ユーザー情報を必要最小限の範囲で開示せざるをえないと判断するに足る十分な根拠がある場合</li>
      <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
      <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合</li>
      <li>国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合</li>
      <li>業務委託契約に基づいて、ユーザー情報の取扱いの一部又は全部を外部の会社に委託する場合</li>
      <li>合併、会社分割、事業譲渡その他の事由によって事業の継承が行われる場合</li>
    </ul>

    <h2>4. 個人情報の開示</h2>
    <p>当運営者は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）但し、個人情報保護法その他の法令により、当運営者が開示の義務を負わない場合は、この限りではありません。</p>

    <h2>5. 個人情報の訂正及び利用停止等</h2>
    <p>当運営者は、ユーザーから、個人情報保護法の定めに基づき個人情報の訂正等または利用停止等を求められた場合には、ユーザーご本人からのご請求であることを確認の上で、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等または利用停止等を行い、その旨をユーザーに通知します。但し、個人情報保護法その他の法令により、当運営者が訂正等及び利用停止等の義務を負わない場合は、この限りではありません。</p>

    <h2>6. 個人情報の削除</h2>
    <p>ユーザーは、当運営者が法令上義務を負わない場合を除き、当運営者所定の手続にしたがって、個人情報の削除を請求することができます。</p>
    <p>削除の請求にあたっては、その旨を記載した上で以下のメールアドレス宛にご連絡ください。メールを受信後に、別途ご案内を差し上げます。</p>
    <p>info(at-mark)healthandrights.jp</p>

    <h2>7. プライバシーポリシーの改定</h2>
    <p>当運営者は、ユーザー情報の取扱いについて継続的に改善するため、本ポリシーを改定することがあります。なお、法令上ユーザーの同意が必要となる内容の改定の場合は、当運営者所定の方法でユーザーの同意を得るものとします。</p>

    <p>2024年3月15日制定</p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyView'
};
</script>

<style scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2 {
  margin-top: 20px;
}

p {
  margin-bottom: 15px;
}

ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}
</style>