<template>
  <div class="terms">
    <h1>メノア利用規約</h1>
    <p>「メノア 利用規約」（以下、「本規約」といいます。）は、メノア株式会社（以下、「当社」といいます。）が当社のアプリにおいて提供する「メノア」サービス（以下、「本サービス」といいます。）の利用条件を定めるものです。本サービスを利用するお客様（以下、「ユーザー」といいます。）には、本規約および本サービス内に掲載される利用条件に従って、本サービスをご利用いただきます。</p>

    <h2>第1条 適用</h2>
    <p>本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</p>

    <h2>第2条 契約の成立</h2>
    <p>ユーザーが本サービスをスマートフォンやその他の情報端末にダウンロードした時点で本規約に同意したものとみなし、当社とユーザーとの間で本サービスの提供にかかる契約（以下、「本契約」といいます。）が成立します。</p>

    <h2>第3条 本規約の変更等</h2>
    <ul>
      <li>当社の判断において、いつでも本規約等の内容を変更又は追加できるものとします。変更後の利用規約等は、当社が別途定める場合を除いて、本サービス上又は当社の運営するウェブサイトへの掲載その他当社所定の方法により告知します。</li>
      <li>ユーザーは、変更後の本規約等に同意しない場合には、本サービスをダウンロードした情報端末から、直ちに本サービスを削除又はアンイストールするものとします。</li>
      <li>ユーザーが本規約等の変更後も本サービスの利用を継続する場合、当該ユーザーは、変更後の本規約等に同意したものとみなされます。なお、当社は、本規約等の変更又は追加につき、原則として、各ユーザーに個別に通知することはいたしません。ユーザーは、ユーザーの責任において、随時、本規約の最新の内容を確認の上、本サービスをご利用ください。</li>
      <li>本規約等の内容と、本規約等以外の本サービスの説明等とが矛盾・抵触する場合は、特段の定めがない限り、本規約等の規定が優先して適用されます。</li>
    </ul>

    <h2>第4条 本サービスの内容</h2>
    <ul>
      <li>当社は、ユーザーに対し、本規約および本サービス内に掲載される利用条件に従って本サービスを提供します。</li>
      <li>本サービスは、月経日などの生体データ、および体調などの記録に基づく月経周期や不調などの予測、ならびに健康の維持・改善に関するアドバイスを提供します。</li>
      <li>ユーザーは、本サービス内の「アシスタント」の機能（以下、「アシスタント機能」といいます。）を利用し、医学・医療に関する一般的な情報を得ることができます。</li>
      <li>本サービスは、ユーザーが私的に使用する目的でのみ利用することができ、販売、配布又は開発等の私的使用以外の目的で使用してはなりません。</li>
      <li>ユーザーは、本サービスを、当社が提供する状態でのみ利用するものとし、本サービスの複製、修正、変更、改変又は翻案を行ってはなりません。</li>
      <li>ユーザーが本サービスを利用するためにはインターネット通信環境が必要になります。また通信にかかる料金はユーザーご自身により負担いただきます。</li>
      <li>当社は、ユーザーに通知することなく、いつでも本サービスの内容を変更し、または本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。</li>
      <li>ユーザーが本サービスと提携するサービスを利用する場合は、当該サービスの利用規約を遵守しなければならず、当社は、当社の故意又は重大な過失がある場合を除き、当該サービスに関してユーザーに生じた損害について責任を負いません。</li>
    </ul>

    <h2>第5条 利用料金</h2>
    <ul>
      <li>本サービスは無料のサービスおよび、定額料金制（金額はプランによって異なります。）の有料サービスです。以下、定額料金を「利用料金」といいます。</li>
      <li>利用料金は、当社の定める手続きにより事前告知なく改定する場合があります。</li>
      <li>利用料金は、通信事業者、クレジットカード会社、決済代行会社、App Store・Google Play等のプラットフォームのうちお支払い時点で利用可能なものおよび弊社が定める条件に従いお支払いいただきます。なお、支払に要する費用は、ユーザーの負担とします。</li>
      <li>利用料金の支払いに関し、お客様と通信事業者、クレジットカード会社、決済代行会社またはApp Store・Google Play等のプラットフォームとの間で紛争が生じた場合は、お客様の責任と費用負担においてこれを解決していただくものとし、弊社は一切責任を負わないものとします。</li>
    </ul>

    <h2>第6条 注意事項</h2>
    <ul>
      <li>本サービスの「アシスタント機能」が提供するサービスは、一般的な医学・医療に関する情報の提供や、一般的な受診勧奨に留まり、相談者の個別的な状態を踏まえた疾患のり患可能性の提示・診断等の医学的判断を行うものではありません。ユーザーは、本サービスよる情報提供が治療行為（具体的な確定診断を下し、治療方法を指示すること）ではないことを十分に認識した上で自己の責任において本サービスを利用するものとし、必ず自身の判断に基づき、必要に応じて適宜医療機関の受診等を行うものとします。</li>
      <li>ユーザーは、本サービスに基づく情報を根拠にした確定的判断を行うことなく、必ず自身の判断に基づき、必要に応じて適宜医療機関の受診等を行ってください。</li>
      <li>当社は、「アシスタント機能」を通じてユーザーより得た情報に関して、個人を特定できる情報を除いた情報を、サービス提供の事例として公開する場合があることについて、ユーザーは予め了承するものとします。</li>
    </ul>

    <h2>第7条 本サービスの不保証</h2>
    <p>当社は、本サービスにより提供する情報に合理的な範囲で注意を払いますが、本サービスに基づき得られる情報の完全性、正確性、有用性等の一切の保証を行うものではありません。また、提供する情報が、常に最新のものであることも保証しません。ユーザーは、ユーザーの自己責任で本サービスに基づく情報の提供を享受するものとします。</p>

    <h2>第8条 参考情報の提供</h2>
    <p>本サービスに掲載されている情報や専門医からのアドバイス、また生理日予測等に従い行った行為の結果については、当社および情報提供元は一切の責任を負いかねます。あくまでもユーザーご自身の責任により本サービスをご利用いただくことについて承諾いただきます。</p>

    <h2>第9条 著作権の帰属</h2>
    <p>本サービスによりユーザーに提供されるコンテンツ、サービス、技術などの情報は、著作権等の知的財産権関連法令で保護されています。ユーザーは、当該情報をユーザーの私的利用で利用する場合に限り利用できます。ユーザーが、本サービスで提供される情報をユーザーによる私的利用目的を越えて（営利目的、非営利目的であることを問いません）自己の事業のために使用（複製、送信、頒布、譲渡、翻案等を含む）するためには、当社の明示の許諾が必要です。</p>

    <h2>第10条 禁止事項</h2>
    <ul>
      <li>ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        <ul>
          <li>本規約に違反する行為</li>
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為もしくはこれを助長する行為</li>
          <li>当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</li>
          <li>本サービスの運営を妨害するおそれのある行為</li>
          <li>当社または当社がライセンスを受けている第三者の知的財産権、その他財産的もしくは属人的な権利、並びにプライバシーを侵害する行為</li>
          <li>当社、他の会員または第三者に不利益もしくは損害を与える行為、またはこれを侵害するおそれのある行為</li>
          <li>本サービスのリバースエンジニアリングその他本サービスのソースコードを解析する行為</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ul>
      </li>
      <li>当社は、ユーザーが前項の行為（以下、「禁止事項」といいます。）のいずれかに該当する行為をなしたと判断した場合は、ユーザーに通告することなく以降の本サービスのご利用を停止させて頂く場合があります。</li>
      <li>当社は、ユーザーが禁止事項のいずれかに該当する行為をなしたことにより当社が損害を生じた場合または生じる恐れがある場合、ユーザーへの損害賠償請求や公的機関への通報・個人情報を含む当該ユーザーに関する情報の開示を行う場合があります。</li>
    </ul>

    <h2>第11条 債権譲渡の禁止</h2>
    <p>ユーザーは、本契約に係るいかなる権利または義務も第三者に譲渡、貸与、担保設定等することはできません。ユーザーについてこれらの行為が行われた場合、当該権利は消滅するものとします。</p>

    <h2>第12条 ユーザーの損害賠償責任</h2>
    <p>ユーザーが、法令または本規約の定めに違反したことにより当社に損害を及ぼした場合には、当該ユーザーは当社に対し、全ての損害を賠償しなければなりません。</p>

    <h2>第13条 本サービスの提供の停止等</h2>
    <ul>
      <li>通信回線設備またはシステムの障害、定期的または緊急のメンテナンスの必要、天災、事変、戦争その他の事態において、本サービスの一部または全部が中断、中止されることがあります。</li>
      <li>当社は、ユーザーが支払うべき利用料金を支払わない場合、その他本規約に違反した場合は、何らの通知または催告なしにユーザーに対する本サービスの提供を停止し、または本契約を解除することができるものとします。</li>
      <li>当社は、前二項の規定によって本サービスの提供を停止し、または本契約を解除した場合、これによってユーザーに生じた損害について一切の責任を負いません。</li>
    </ul>

    <h2>第14条 当社の損害賠償責任</h2>
    <ul>
      <li>当社は、本サービスの提供に関連し、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。</li>
      <li>本契約が消費者契約法に定める消費者契約に該当する場合、本規約の各規定における当社の免責は適用されないものとします。この場合において、当社がユーザーに対して損害賠償義務を負う場合、賠償すべき損害の範囲は、ユーザーに現実に発生した通常の損害に限る（逸失利益を含む特別の損害は含まない。）ものとし、賠償すべき損害の額は、当該損害にかかる通信料相当額（最大100円）を限度とします。ただし、当社の故意または重過失による損害の場合は、この限りではありません。</li>
    </ul>

    <h2>第15条 本サービスの利用終了</h2>
    <ul>
      <li>ユーザーは、本サービスの削除・アンインストールその他の方法により、いつでも本サービスの利用を終了することができます。本サービスの利用を終了したユーザーは、当該終了時点から本アプリを利用することができなくなります。</li>
      <li>ユーザーは、本サービス利用終了後も、当社及び第三者に対する本規約上の義務及び債務（損害賠償を含みますが、これに限りません。）を免れるものではありません。</li>
      <li>当社は、ユーザーが本サービスの利用を終了した後も、ユーザー情報等を保有・利用することができるものとします。</li>
      <li>ユーザーが本サービスの利用を終了した場合、当社は、当社の裁量により、事前に通知することなく本アプリ内のユーザー情報その他一切の情報が消滅する場合があることに同意するものとします。</li>
    </ul>

    <h2>第16条 反社会的勢力の排除</h2>
    <ul>
      <li>ユーザーは、当社に対して、ユーザーが本規約に同意した日および将来にわたって、自己が次の各号に掲げる者（本規約において、「反社会的勢力」といいます）でないことを表明し、確約します。
        <ul>
          <li>暴力団、暴力団の構成員（準構成員を含みます）または暴力団の構成員でなくなった日から５年を経過しない者</li>
          <li>暴力団関係企業</li>
          <li>総会屋、社会運動標榜ゴロ、政治活動標榜ゴロ、特殊知能暴力集団またはこれらの団体の構成員</li>
          <li>前各号に準じるもの</li>
        </ul>
      </li>
      <li>ユーザーは、次の各号のいずれかに該当する行為もしくは該当するおそれのある行為を行わず、または第三者をして行わせしめないことを相手方に対して表明し、確約します。
        <ul>
          <li>暴力的な要求行為</li>
          <li>法的な責任を超えた不当な要求行為</li>
          <li>取引に関して、脅迫的な言動を行い、または暴力を用いる行為</li>
          <li>風説の流布、偽計もしくは威力を用いて信用を毀損し、または業務を妨害する行為</li>
          <li>前各号に準じる行為</li>
        </ul>
      </li>
      <li>当社は、お客様が前2項に定める表明確約に反した場合は、将来に向かって直ちに本サービスの解除を行うことができます。</li>
      <li>当社は、本条に定める解除を行った場合であっても、ユーザーに対する損害賠償請求権を失わないものとします。なお、この場合ユーザーは当社に対して損害賠償を請求することはできません。</li>
    </ul>

    <h2>第17条 情報の取得およびその取扱い</h2>
    <ul>
      <li>本サービスを通じて当社が取得するユーザーの情報については、別紙「株式会社ヘルスアンドライツ プライバシーポリシー」をご覧ください。</li>
      <li>前項の情報は、当社のサービスおよび提携企業の商品開発、機能評価・改善、マーケティング分析、広告配信、その他の当社の事業目的のために、個人を特定出来ない形態にした上で利用させていただく場合があります。</li>
      <li>本サービスには、月経日などの生体データや体調などの身体情報等（以下、「機微情報」といいます。）の入力が必要となる機能がございます。機微情報の入力が必要なサービスは、入力した機微情報が当社に通知されることを承諾していただいたうえで、ユーザーの判断と責任においてご利用ください。</li>
      <li>ユーザーが本サービスにおいて入力した情報の一部は、ユーザーが本サービスにおいて逐一情報を入力することなく、共通してサービス上ご利用頂ける自己の情報として記録・管理されます。</li>
    </ul>

    <h2>第18条 準拠法・裁判管轄</h2>
    <ul>
      <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
      <li>本サービスに関して紛争が生じた場合には、東京地方裁判所または東京簡易裁判所を専属的合意管轄とします。</li>
    </ul>

    <p>2024年3月15日制定</p>
  </div>
</template>

<script>
export default {
  name: 'TermsView'
};
</script>

<style scoped>
.terms {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1, h2, h3 {
  margin-top: 20px;
}

p {
  margin-bottom: 15px;
}

ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}
</style>