<template>
        <footer>
                <nav>
                        <router-link to="/">TOP</router-link>
                        <router-link to="/terms">利用規約</router-link>
                        <router-link to="/privacy-policy">プライバシーポリシー</router-link>
                        <router-link to="/legal">特定商取引法に基づく表記</router-link>
                        <router-link to="/company">会社概要</router-link>
                </nav>
                <p>© 2024 menoah, Inc. All rights reserved.</p>
        </footer>
</template>

<script>
export default {
        name: 'FooterComponent'
};
</script>

<style>
footer {
        text-align: center;
        padding: 20px;
        background-color: #ffffff;
        color: #000000;
}
nav {
        margin-bottom: 20px;
}
nav a {
        margin-right: 15px;
        text-decoration: none;
        color: #060d0a;
}
</style>
