<template>
  <div class="legal-page">
    <h1>特定商取引法に基づく表記</h1>
    <dl>
      <dt>事業者名</dt>
      <dd>メノア株式会社</dd>

      <dt>所在地</dt>
      <dd>東京都渋谷区 渋谷1-1-3 アミーホール</dd>

      <dt>代表者</dt>
      <dd>代表取締役 吉川雄司</dd>

      <dt>お問い合わせ先</dt>
      <dd>info@menoah.jp</dd>

      <dt>利用料</dt>
      <dd>購入手続きの際に画面に表示されます。消費税は内税として表示しております。</dd>

      <dt>利用料以外に発生する料金等</dt>
      <dd>サイトの閲覧、コンテンツのダウンロード、お問い合わせ等の際の電子メールの送受信時などに、所定の通信料が発生します。</dd>

      <dt>決済方法</dt>
      <dd>
        <ul>
          <li>ネイティブアプリ：クレジットカード決済の他、携帯電話会社などによる収納をご利用いただけます。</li>
        </ul>
      </dd>

      <dt>サービス開始時期と終了時期</dt>
      <dd>当社所定の手続き終了後、直ちにご利用頂けます。 契約期間途中の解約となった場合であっても契約満了日までの料金が発生し、日割精算等による返金を含めた一切の返金は行われません。その場合、サービスも契約満了日まで提供されます。</dd>

      <dt>返品についての特約事項</dt>
      <dd>電子商品としての性質上、返品には応じられません。</dd>

      <dt>推奨環境</dt>
      <dd>
        <ul>
          <li>OS：最新のOS</li>
          <li>アプリバージョン：App StoreまたはPlay Storeで提供している最新バージョン</li>
          <li>ブラウザ：最新のSafariまたはGoogle Chrome</li>
        </ul>
      </dd>

      <dt>注意事項</dt>
      <dd>本サービスは、特定商取引法に規定されるクーリング・オフが適用されるサービスではありません。</dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'LegalView',
  mounted() {
    document.title = '特定商取引法に基づく表記 | メノア株式会社';
  }
};
</script>

<style scoped>
.legal-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
}

h2 {
  color: #444;
  border-bottom: 2px solid #ddd;
  padding-bottom: 10px;
  margin-top: 30px;
}

dl {
  margin-left: 20px;
}

dt {
  font-weight: bold;
  margin-top: 10px;
}

dd {
  margin-left: 0;
  margin-bottom: 10px;
}

ul {
  padding-left: 20px;
}
</style>
